<template>
  <FDropdown
    no-styles
    block-class="select-language-popper"
    @visible-change="isDropdownOpen = !isDropdownOpen"
  >
    <template #title>
      <slot name="title" :language-object="languageObject" :is-open="isDropdownOpen">
        <LangTitleBasic
          v-if="!isShort"
          :lang="languageObject?.text"
          :is-open="isDropdownOpen"
          :type="type"
        />
        <LangTitleShort v-else :lang="languageObject?.short" />
      </slot>
    </template>

    <template #content>
      <DropdownItem
        v-for="(lang, i) in languages"
        :key="i"
        class="language-item"
        :class="{
          'is-disabled': Boolean(lang.soon),
        }"
        no-paddings
        size="large"
        @click="setLanguage(lang.value)"
      >
        <AppText size="14px" class="flex-grow-1">
          {{ lang.text }}
        </AppText>
        <AppText size="12px">
          {{ lang.soon }}
        </AppText>
      </DropdownItem>
    </template>
  </FDropdown>
</template>

<script>
import { ref } from 'vue';

import DropdownItem from '@/components/ElementUI/DropdownItem.vue';

import { useLang } from '@/composables/useLang';

import LangTitleBasic from './components/LangTitleBasic.vue';
import LangTitleShort from './components/LangTitleShort.vue';

export default {
  name: 'SelectLanguage',
  components: {
    DropdownItem,
    LangTitleBasic,
    LangTitleShort,
  },
  props: {
    isShort: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'grey',
      validate: (value) => ['grey', 'black'].includes(value),
    },
  },
  setup() {
    const { languageObject, languages, setLanguage } = useLang();
    const isDropdownOpen = ref(false);

    return {
      languageObject,
      languages,
      setLanguage,
      isDropdownOpen,
    };
  },
};
</script>

<style lang="scss" scoped>
.language-item {
  &.is-disabled {
    cursor: not-allowed;
    background: none;

    :deep(.text) {
      opacity: 0.2;
    }
  }
}
</style>

<template>
  <CustomScroll>
    <div class="client-layout">
      <div ref="wrapper" class="d-flex flex-column align-items-center">
        <div class="client-layout-wrapper">
          <div class="header">
            <router-link to="/">
              <AppIcon
                is-img-tag
                name="switch-dnd"
                width="96px"
              />
            </router-link>

            <div class="switch-link-wrap">
              <ExternalLink
                color="var(--color-black-05)"
                hover-color="var(--color-black)"
                :href="switchLink"
              >
                {{ t('dnd.returnTo', { link: productionURL }) }}
              </ExternalLink>
            </div>

            <FSpacer />

            <SelectLanguage>
              <template #title="{ languageObject, isOpen }">
                <LangTitleBasic
                  type="black"
                  :lang="languageObject?.text"
                  :is-open="isOpen"
                />
              </template>
            </SelectLanguage>
            <FButton
              v-if="isLogoutVisible"
              type="transparent"
              class="logout-button"
              @click="onLogout"
            >
              <AppIcon name="power" size="18px" />
              <AppText class="font-medium" color="var(--color-black)">
                {{ t('header.menu.logout') }}
              </AppText>
            </FButton>
          </div>
          <div ref="wrapperContent" class="wrapper-content">
            <router-view v-slot="{ Component }">
              <Transition name="show">
                <component :is="Component" />
              </Transition>
            </router-view>
          </div>
        </div>
        <div class="footer">
          <AppText mr="20px" :opacity="0.5">
            {{ t('dnd.help') }}
          </AppText>
          <a
            class="link support-link"
            :href="emailLink"
            rel="noopener noreferrer"
            target="_blank"
          >
            <AppIcon
              class="mail"
              name="mail"
              size="18px"
              :opacity="0.4"
            />
            <span>{{ email }}</span>
          </a>
        </div>
      </div>
    </div>
  </CustomScroll>
</template>

<script>
import { computed } from 'vue';

import ExternalLink from '@/components/ExternalLink.vue';
import LangTitleBasic from '@/components/SelectLanguage/components/LangTitleBasic.vue';
import SelectLanguage from '@/components/SelectLanguage';

import { logout } from '@/api/auth';
import { useRoute } from '@/router';
import { BASE_URL } from '@/composables/useApp';

export default {
  components: {
    SelectLanguage,
    LangTitleBasic,
    ExternalLink,
  },
  setup() {
    const switchLink = BASE_URL;
    const route = useRoute();

    const isLogoutVisible = computed(() => route.path.includes('/settings'));

    const onLogout = () => {
      logout({
        pushToHome: true,
        useLogoutEndpoint: false,
      });
    };

    const productionURL = process.env.VUE_APP_PRODUCTION_URL;
    const email = `support@${productionURL}`;
    const emailLink = `mailto:${email}`;

    return {
      onLogout,
      isLogoutVisible,
      switchLink,
      productionURL,
      email,
      emailLink,
    };
  },
};
</script>

<style scoped lang="scss">
.client-layout {
  background-color: #f7f7f7;
  min-height: 100vh;
}

.client-layout-wrapper{
  position: relative;
  background-color: var(--color-white);
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
  min-height: 500px;
  margin: 50px 0 30px;

  transition: all 0.1s cubic-bezier(0, 1.2, 1, 0.97);
}

.header {
  padding: 0 40px;
  border-bottom: 1px solid var(--color-black-01);

  display: flex;
  align-items: center;
  height: 80px;
}

.switch-link-wrap {
  height: 16px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding-left: 13px;
  border-left: 1px solid #D9D9D9;
}

.wrapper-content {
  width: 100%;
  position: relative;
}

.logout-button {
  margin-right: -30px;
  margin-left: 20px;

  &:hover {
    :deep(.text) {
      color: var(--color-error);
    }
    :deep(.icon path) {
      fill: var(--color-error);
    }
  }
}

.support-link {
  display: flex;
  align-items: center;
  font-size: 13px;

  span {
    opacity: 0.5;
    @include transition-base('opacity, color')
  }

  &:hover {
    span {
      opacity: 1;
      color: var(--color-primary);
    }
  }

  :deep(.icon) {
    margin-right: 9px;
  }
}

:deep(.text__class.info) {
  @include font-regular;
}

.footer {
  @include flex-center;
  margin-bottom: 60px;
}
</style>

<template>
  <div class="lang">
    <AppIcon
      name="globe-world"
      size="12px"
      is-img-tag
    />

    <AppText>
      {{ lang }}
    </AppText>
  </div>
</template>

<script>
export default {
  name: 'LangTitleShort',
  props: {
    lang: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.lang {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 24px;
  padding: 0 8px;
  background: var(--color-black-005);
  border-radius: 40px;

  &:hover {
    background: var(--color-black-01);
  }
}
</style>

<template>
  <div class="lang" :class="type">
    <AppIcon
      :name="iconName"
      :size="iconSize"
      is-img-tag
    />

    <AppText class="info" :class="{ 'font-semibold': type === 'grey' }">
      {{ lang }}
    </AppText>

    <DropdownArrow :is-open="isOpen" />
  </div>
</template>

<script>
import { computed } from 'vue';

import DropdownArrow from '@/components/Icon/DropdownArrow.vue';

export default {
  name: 'LangTitleBasic',
  components: {
    DropdownArrow,
  },
  props: {
    lang: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'grey',
      validate: (value) => ['grey', 'black'].includes(value),
    },
  },
  setup(props) {
    const iconName = computed(() => {
      if (props.type === 'black') {
        return 'globe-world';
      }

      return 'world-grey';
    });

    const iconSize = computed(() => {
      if (props.type === 'black') {
        return '12px';
      }

      return '20px';
    });

    return {
      iconName,
      iconSize,
    };
  },
};
</script>

<style lang="scss" scoped>
.lang {
  cursor: pointer;
  display: flex;
  align-items: center;
  @include transition-base('opacity');

  .info {
    margin: 0 6px 0 8px;
  }

  &.grey:hover {
    opacity: 0.5;
  }

  &.black {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}
</style>

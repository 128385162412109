<template>
  <component
    :is="isBlock ? 'div' : 'a'"
    tabindex="0"
    :href="isBlock ? undefined : href"
    :target="isBlock ? undefined : target"
    class="external-link"
    :class="{
      'is-primary': hasPrimaryColor,
      'is-disabled': isDisabled,
      'is-append-hover': isAppendHover,
    }"
    @keypress.space="onSpacePress"
  >
    <div v-if="hasPrepend" class="flex-center external-link--prepend">
      <slot name="prepend" />
    </div>
    <template v-if="!isBlock">
      <span class="text-link">
        <slot />
      </span>
    </template>
    <template v-else>
      <div class="text-link">
        <slot />
      </div>
    </template>
    <div v-if="hasAppend" class="append flex-center">
      <slot name="append">
        <AppIcon class="arrow-icon" name="external-link" size="12px" />
      </slot>
    </div>
  </component>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ExternalLink',
  props: {
    target: {
      type: String,
      default: '_blank',
    },
    href: {
      type: String,
      default: `https://${process.env.VUE_APP_DOCS_URL}`,
    },
    hasAppend: {
      type: Boolean,
      default: true,
    },
    hasPrepend: {
      type: Boolean,
      default: true,
    },
    hasPrimaryColor: {
      type: Boolean,
      default: false,
    },
    hoverColor: {
      type: String,
      default: 'var(--color-primary)',
    },
    color: {
      type: String,
      default: 'var(--color-black-05)',
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '13px',
    },
    lineHeight: {
      type: Number,
      default: 1.35,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isAppendHover: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const onSpacePress = (e) => {
      e.preventDefault();
      window.open(props.href, '_blank').focus();
    };

    return { onSpacePress };
  },
});
</script>

<style scoped lang="scss">
.external-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 4px;
  @include focus-visible;

  .arrow-icon {
    :deep(path) {
      fill: v-bind('color');
    }
  }

  &.is-primary {
    .text-link {
      color: var(--color-primary);
    }

    .arrow-icon {
      :deep(path) {
        fill: var(--color-primary);
      }
    }
  }

  &:hover {
    .text-link {
      color: v-bind('hoverColor');
    }
    .external-link--prepend {
      :deep(path) {
        fill: v-bind('hoverColor');
      }
    }

    .arrow-icon {
      :deep(path) {
        fill: v-bind('hoverColor');
      }
    }
  }

  &.is-primary:hover {
    .text-link {
      color: darken(#D48343, 8%);
    }

    .arrow-icon {
      :deep(path) {
        fill: darken(#D48343, 8%);
      }
    }
  }

  &.is-disabled {
    cursor: default;
    opacity: 0.5;
    &:hover {
      opacity: 0.5;
      .text-link {
        color: v-bind('color');
      }
    }
  }

  &.is-append-hover {
    .append {
      opacity: 0;
      @include transition-base('opacity');
    }

    &:hover > .append {
        opacity: 1;
    }
  }

  &--prepend {
    margin-right: 4px;
  }
}

.text-link {
  text-decoration: none;
  color: v-bind('color');
  display: flex;
  align-items: center;
  font-size: v-bind('size');
  line-height: v-bind('lineHeight');
  // @include transition-base(color);
  word-break: break-all;
}
</style>
